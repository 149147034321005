import { Title } from '../../components/Title';
import { ContainerHead, Icon, Subtitle, Wrapper, ContainerList, ProductListTitle, TitleIcon } from './Products.parts';
import avocado from '../../assets/icons/avocado.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectorStep } from '../../store/view/view.selectors';
import React, { useEffect, useState } from 'react';
import { setStep } from '../../store/view/view.reducers';
import { Button } from '../../components/Button';
import { Product } from '../../components/Product';
import { selectorCart } from '../../store/cart/cart.selectors';
import { getExclusions, getProducts } from './Products.helpers';
import { Exclusion, ProductList } from './Products.types';
import { useScrollTop } from '../../hooks/useScrollTop';
import TagManager from 'react-gtm-module';
import { ReactGTMOptions } from '../../constants';
import ReactPixel from 'react-facebook-pixel';

export const Products = () => {
  const [productsList, setProductsList] = useState<ProductList[] | []>([]);
  const [exclusions, setExclusions] = useState<Exclusion[]>([]);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const activeStep = useSelector(selectorStep);
  const { products, address } = useSelector(selectorCart);

  useScrollTop();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: ReactGTMOptions({ title: 'Checkout: Products' }),
    });
    ReactPixel.track('ViewContent', { content_name: 'Checkout: Products' });
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await getProducts(address.postcode);
        setError(false);
        setProductsList(res);
      } catch (e) {
        setProductsList([]);
        setError(true);
      }
    };

    void fetchProducts();
  }, []);

  useEffect(() => {
    const fetchExclusions = async () => {
      try {
        const res = await getExclusions();
        setExclusions(res);
      } catch (e) {
        console.error(e, 'getExclusions() error');
        setExclusions([]);
      }
    };

    void fetchExclusions();
  }, []);

  const nextHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(setStep(activeStep + 1));
  };

  return (
    <Wrapper>
      <ContainerHead>
        {error ? (
          <>
            <Title>Something went wrong. Please try again later.</Title>
          </>
        ) : (
          <>
            <Title>CHOOSE YOUR BOX</Title>
            <Subtitle>We deliver throughout the day on Thursdays and Fridays.</Subtitle>
            <Icon>
              <img src={avocado} alt="avocado" />
            </Icon>
          </>
        )}

        {!!products.length && <Button onClick={nextHandler}>Next</Button>}
      </ContainerHead>
      {productsList.map((product, index) => (
        <div key={product.title}>
          <ProductListTitle>
            <TitleIcon>
              <span>{index + 1}</span>
            </TitleIcon>
            {product.title}
          </ProductListTitle>
          <ContainerList>
            {product.sizes.map(item => (
              <Product key={item.product_variant_id} product={item} title={product.title} exclusions={exclusions} />
            ))}
          </ContainerList>
        </div>
      ))}
    </Wrapper>
  );
};
