export const COLORS = {
  oliveText: '#787d2f',
  orange: '#ecbf4b',
  grey01: '#330',
  grey02: '#333',
};

export const ReactGTMOptions = ({ title }: { title: string }) => ({
  event: 'pageview',
  page: {
    url: '/checkout',
    title,
  },
});

export const REACT_GTM_ID = `${process.env.REACT_APP_GTM_ID}`;

export const FB_PIXEL_ID = `${process.env.REACT_APP_FB_PIXEL_ID}`;
