import React, { useEffect, useRef, useState } from 'react';
import {
  ActiveButton,
  ButtonWrap,
  ByButton,
  Container,
  DeliveryContainer,
  DeliveryLink,
  DeliveryText,
  DeliveryTitle,
  ModalContentText,
  ModalContentWrap,
  ProductImg,
  Text,
  Title,
  WrapDeliveryTime,
} from './Product.parts';
import productImg from '../../assets/img/product.jpg';
import { Select } from '../Select';
import { Button } from '../Button';
import { Input } from './Input';
import { Checkbox } from '../Checkbox';
import { Modal } from '../Modal';
import { setStep } from '../../store/view/view.reducers';
import { addProduct, removeProduct } from '../../store/cart/cart.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { selectorStep } from '../../store/view/view.selectors';
import { ExclusionsToSelect, ProductTypesProps } from './Product.types';
import { dateFormat } from '../../helpers/dateFormat';
import { default as ReactSelect } from 'react-select';
import { adaptFromSelect, adaptToSelect } from './Product.helpers';
import './SelectStyles.css';

const EXCLUSIONS_LINK = 'https://www.wonkybox.nz/terms-of-service-policies/exclusions-box-customisation';
const MAX_SELECT_COUNT = 3;

export const Product = ({ product, exclusions, title }: ProductTypesProps) => {
  const dispatch = useDispatch();
  const activeStep = useSelector(selectorStep);

  const deliveryStepRef = useRef<HTMLLIElement>(null);
  const [deliveryStep, setDeliveryStep] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [delivery, setDelivery] = useState<string>(product.start_dates[0]);
  const [amount, setAmount] = useState('1');
  const [frequency, setFrequency] = useState<number>(product.available_frequencies[0].frequency_id);
  const [exclusionList, setExclusionList] = useState<ExclusionsToSelect[]>([]);

  useEffect(() => {
    deliveryStepRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [deliveryStep]);

  const nextHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(setStep(activeStep + 1));
  };

  const canselHandler = () => {
    setDeliveryStep(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const addProductHandler = () => {
    const frequencyValue = product.available_frequencies.find(item => item.frequency_id === frequency);
    const exclusions = adaptFromSelect(exclusionList);
    dispatch(
      addProduct({
        quantity: amount,
        start_date: delivery,
        frequency_id: frequency,
        frequency: frequencyValue?.name || '',
        product_variant_id: product.product_variant_id,
        price: product.price,
        size: product.size,
        title: title,
        img: product.image_url,
        exclusions,
      }),
    );
    setDeliveryStep(false);
    setIsOpen(true);
    setIsActive(true);
  };

  const removeProductHandler = () => {
    setIsActive(false);
    dispatch(
      removeProduct({
        id: product.product_variant_id,
      }),
    );
  };

  return (
    <>
      {!deliveryStep ? (
        <Container isActive={isActive}>
          <Title>{product.size}</Title>
          <ProductImg>
            <img src={product.image_url ? product.image_url : productImg} alt="" />
          </ProductImg>
          <Text>{product.description}</Text>
          <Select setActive={setFrequency} label="Frequency" options={product.available_frequencies} />
          <Input amount={amount} setAmount={setAmount} />
          {isActive ? (
            <ButtonWrap>
              <ActiveButton>Active | ${product.price.toFixed(2)}</ActiveButton>
              <Button filled={false} onClick={() => removeProductHandler()}>
                Remove
              </Button>
            </ButtonWrap>
          ) : (
              <ByButton onClick={() => {
                if (frequency === 0) {
                  addProductHandler()
                } else {
                  setDeliveryStep(true)
                }
              }
              }>
                CHOOSE BOX | <strong>${product.price.toFixed(2)}</strong>
              </ByButton>
          )}
        </Container>
      ) : (
        <>
          <DeliveryContainer ref={deliveryStepRef} isActive={isActive}>
            <DeliveryTitle>Select delivery start day:</DeliveryTitle>
            {product.start_dates.map((item, index) => (
              <WrapDeliveryTime key={item}>
                <Checkbox
                  label={dateFormat(item)}
                  id={`${product.product_variant_id}_${item}`}
                  value={item}
                  name={`${product.size}_${product.product_variant_id}`}
                  type="radio"
                  onClick={() => setDelivery(item)}
                  checked={delivery ? delivery === item : index === 0}
                />
              </WrapDeliveryTime>
            ))}
            <DeliveryTitle>exclusions:</DeliveryTitle>
            <DeliveryText>Select up to 3 exclusions.</DeliveryText>
            <DeliveryLink target="_blank" href={EXCLUSIONS_LINK}>
              Read our exclusions policy here
            </DeliveryLink>
            <ReactSelect
              className="react-select-container"
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              value={exclusionList}
              isMulti
              isSearchable={false}
              options={adaptToSelect(exclusions)}
              isOptionDisabled={() => exclusionList.length >= MAX_SELECT_COUNT}
              onChange={values => setExclusionList(values as ExclusionsToSelect[])}
            />
            <ButtonWrap>
              <Button onClick={addProductHandler}>Add now</Button>
              <Button filled={false} onClick={canselHandler}>
                Cancel
              </Button>
            </ButtonWrap>
          </DeliveryContainer>
        </>
      )}

      <Modal width={'750px'} isOpen={isOpen} closeModal={closeModal} title={`${amount} box added to your subscription`}>
        <ModalContentWrap>
          <ModalContentText>Choose another box to add to your subscription, or head to checkout.</ModalContentText>
          <ButtonWrap>
            <Button onClick={closeModal}>Add another box</Button>
            <Button filled={false} onClick={nextHandler}>
              Go to next step
            </Button>
          </ButtonWrap>
        </ModalContentWrap>
      </Modal>
    </>
  );
};
