import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from '../../components/Title';
import { Input } from './Input';
import { Button } from '../../components/Button';
import { setStep } from '../../store/view/view.reducers';
import { selectorStep } from '../../store/view/view.selectors';
import {
  Container,
  Content,
  FormContainer,
  ImgContainer,
  ImgWrapper,
  ModalContent,
  SuggestionsItem,
  SuggestionsList,
} from './AddressChecker.parts';
import boxCabbage from '../../assets/img/boxCabbage.svg';
import { Modal } from '../../components/Modal';
import { ButtonWrap, ModalContentText, ModalContentTextNote } from '../../components/Modal/Modal.parts';
import { WaitingListForm } from '../../components/WaitingListForm';
import Pepper from '../../assets/img/Wonky-BoxPepper.png';
import { suggestionAddress, validateAddress } from './AddressChecker.helpers';
import { addValidAddress } from '../../store/cart/cart.reducers';
import TagManager from 'react-gtm-module';
import { ReactGTMOptions } from '../../constants';

export const AddressChecker = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(selectorStep);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestion, setSuggestion] = useState('');

  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: ReactGTMOptions({ title: 'Checkout: Address check' }),
    });
    ReactPixel.track('ViewContent', { content_name: 'Checkout: Address check' });
  }, []);

  const checkHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const valid = await validateAddress(value);

    if (valid?.error) {
      alert(valid.error);
    }

    if (valid.result === 'The address is acceptable') {
      dispatch(setStep(activeStep + 1));
      dispatch(addValidAddress({ ...valid, address: value }));
    } else {
      setFirstModal(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (value === suggestion) {
      return;
    }
    const delayDebounceFn = setTimeout(async () => {
      if (value.length > 3) {
        setLoading(true);
        const suggestions = await suggestionAddress(value);
        setSuggestions(suggestions);
        setSuggestion('');
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const closeModals = () => {
    setFirstModal(false);
    setSecondModal(false);
    setThirdModal(false);
  };

  return (
    <Container>
      <Title>CHECK IF WE DELIVER TO YOUR ADDRESS</Title>
      <FormContainer>
        <Input
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder="Start typing your address or postcode"
        />
        <Button loading={loading} type="submit" onClick={checkHandler} disabled={!suggestion || loading}>
          Check
        </Button>
        {!!suggestions.length && (
          <SuggestionsList>
            {suggestions.map(item => (
              <SuggestionsItem
                key={item}
                onClick={() => {
                  setValue(item);
                  setSuggestions([]);
                  setSuggestion(item);
                }}
              >
                {item}
              </SuggestionsItem>
            ))}
          </SuggestionsList>
        )}
      </FormContainer>
      <Content>
        <p>Our delivery days vary by postcode for efficient delivery routes and to keep our carbon emissions low.</p>
        <p>
          <strong>
            Already have an account? <a href="https://account.wonkybox.nz">Sign in</a>
          </strong>
        </p>
      </Content>
      <ImgContainer>
        <img src={boxCabbage} alt="icon" />
      </ImgContainer>

      <Modal
        width={'930px'}
        isOpen={firstModal}
        closeModal={closeModals}
        title="SORRY, WE DON'T CURRENTLY DELIVER TO YOUR POSTCODE."
      >
        <ModalContent>
          <ModalContentText>
            Fear not, we are expanding all the time. If you'd like to be notified as soon as we deliver to your area,
            please join our waiting list below.
          </ModalContentText>
          <ModalContentTextNote>Note: Please double check you entered your full postcode.</ModalContentTextNote>
          <ButtonWrap>
            <Button
              onClick={() => {
                setValue('');
                setSuggestion('');
                setSuggestions([]);
                closeModals();
              }}
            >
              TRY ANOTHER ADDRESS
            </Button>
            <Button
              filled={false}
              onClick={() => {
                setSecondModal(true);
                setFirstModal(false);
              }}
            >
              JOIN OUR WAITING LIST
            </Button>
          </ButtonWrap>
        </ModalContent>
      </Modal>

      <Modal width={'930px'} isOpen={secondModal} closeModal={closeModals} title="ENTER YOUR DETAILS TO JOIN">
        <ModalContent>
          <WaitingListForm setThirdModal={setThirdModal} closeModal={closeModals} />
        </ModalContent>
      </Modal>

      <Modal
        width={'930px'}
        isOpen={thirdModal}
        closeModal={closeModals}
        title="THANK YOU FOR JOINING OUR WAITING LIST!"
      >
        <ModalContent>
          <ModalContentText>
            Once we expand our delivery services to your area, you will receive an email notification with all the
            exciting details.
          </ModalContentText>
          <ButtonWrap>
            <Button
              onClick={() => {
                setValue('');
                closeModals();
              }}
            >
              TRY ANOTHER ADDRESS
            </Button>
            <Button
              filled={false}
              onClick={() => {
                alert('redirect');
                closeModals();
              }}
            >
              Go to home page
            </Button>
          </ButtonWrap>
          <ImgWrapper>
            <img src={Pepper} alt="pepper" />
          </ImgWrapper>
        </ModalContent>
      </Modal>
    </Container>
  );
};
